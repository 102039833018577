<!-- <template>
  <div class="w-100">
    <div>
      <h4 class="dashboard__heading">Assigned Counsellor</h4>
    </div>
    <div>
      <div class="d-flex" style="flex-wrap: wrap;gap: 20px;">

          <b-card
          class="card-app-design assigned-staff-card"
          style="width: 32%;"
          v-for="(staff, index) in assigned_staff"
          :key="'assigned-staff-' + index"
        >
          <div class="d-flex">
            <div>
              <b-avatar :src="FILESURL + staff.user_profile_image" size="100" />
            </div>
            <div class="pl-2">
              <b-card-title class="mt-1 mb-75">{{
                staff.user_firstname + " " + staff.user_lastname
              }}</b-card-title>
              <b-card-text class="mb-2">
                Cousellor details goes here Cousellor details goes here Cousellor
                details goes here
              </b-card-text>
              <b-card-text v-if="!staff.user_mobile" class="mb-2 primary-color">
                {{ staff.user_email }} | +91 {{ staff.user_mobile }}
                {{ staff.user_email }}
              </b-card-text>
              <b-card-text v-else class="mb-2 primary-color">
                {{ staff.user_email }} | +91 {{ staff.user_mobile }}
                {{ staff.user_email }} | {{ staff.user_mobile }}
              </b-card-text>
            </div>
          </div>
          <br />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="
              $router.push({
                name: 'chat',
              })
            "
          >
            Lets Chat
          </b-button>
        </b-card>
      </div>
    </div>
  </div>
</template> -->

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    assigned_staff: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILESURL,
    };
  },
};
</script>

<style>
.assigned-staff-card {
  font-weight: 600 !important;
}
.black-color {
  color: #000;
}
</style>
