<template>
  <div class="mb-2">
    <div>
      <h4 class="dashboard__heading">Profile Completion</h4>
    </div>
    <div class="mb-lg-3 mb-2">
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper profile-status__progress-grid">
          <div>
            <b-progress :value="profile_data.profile_completed" max="100" />
          </div>
          <div>
            <h4 class="primary-tertiary-color font-weight-bold mb-0">
              {{ parseInt(profile_data.profile_completed) }}%
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div
      class="profile-status__cards-grid"
      v-if="profile_data.profile_completed !== 100"
    >
      <b-card
        :class="
          profile_data.personal_details
            ? 'card-app-design profile-status__card active'
            : 'card-app-design profile-status__card'
        "
      >
        <div class="d-flex">
          <div>
            <h1 class="step-num">1</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Personal Details</b-card-title
            >
            <b-card-text class="step-status">
              {{ profile_data.personal_details ? "Completed" : "Pending" }}
            </b-card-text>
          </div>
        </div>
      </b-card>
      <b-card
        :class="
          profile_data.preferences
            ? 'card-app-design profile-status__card active'
            : 'card-app-design profile-status__card'
        "
      >
        <div class="d-flex">
          <div>
            <h1 class="step-num">2</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name"> Questions</b-card-title>
            <b-card-text class="step-status">
              {{ profile_data.preferences ? "Completed" : "Pending" }}
            </b-card-text>
          </div>
        </div>
      </b-card>
      <b-card
        :class="
          profile_data.documents_uploaded
            ? 'card-app-design profile-status__card active'
            : 'card-app-design profile-status__card'
        "
      >
        <div class="d-flex">
          <div>
            <h1 class="step-num">3</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">Documents</b-card-title>
            <b-card-text class="step-status">
              {{ profile_data.documents_uploaded ? "Completed" : "Pending" }}
            </b-card-text>
          </div>
        </div>
      </b-card>
    </div>
    <!-- <b-card title="Refer Link">
      <a target="_blank" :href="`${DASHBOARD_BASEURL}/register?refer_user_id=${user_id}`">{{
        `${DASHBOARD_BASEURL}/register?refer_user_id=${user_id}`
      }}</a>
    </b-card> -->
  </div>
</template>
<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    profile_data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    user_id() {
      return this.$store.getters["user/getUserDetails"].user_id;
    },
    DASHBOARD_BASEURL() {
      return window.location.origin;
    },
  },
  data() {
    return {
      // profile_data: this.$props.profile_data
    };
  },
};
</script>
<style>
.profile-status__progress-grid {
  display: grid;
  grid-template-columns: 80% 10%;
  grid-column-gap: 5%;
  align-items: center;
}
.profile-status__cards-grid {
  display: grid;
  /* grid-template-columns: repeat(5, 18%); */
  grid-template-columns: repeat(3, 32%);
  grid-column-gap: 2%;
}
.profile-status__card {
  background: #ffffff;
  font-weight: 600;
  padding: 10px 05px;
  /* border-radius: 12px; */
  /* padding: 1.5rem 1rem !important; */
  /* display: grid;
  grid-template-columns: 20% 80%; */
}

.profile-status__card .step-num {
  color: #8196C6;
  font-size: 45px;
  font-weight: bold;
  margin-top: -6px;
}
.profile-status__card .step-name {
  font-weight: bold;
  color: #000;
  line-height: 22px;
  font-size: 1.2rem;
  /* padding-left: 15px; */
}

.profile-status__card .step-status {
  color: #c8c8c8;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 1rem !important;

  /* margin-left: 2rem !important; */
}
.profile-status__card.active {
  background: #90BB81;
  color: #fff;
}
.profile-status__card.active .step-num {
  color: #fff;
}
.profile-status__card.active .step-name {
  color: #fff;
}
.profile-status__card.active .step-status {
  color: #171717;
}
@media (max-width: 768px) {
  .profile-status__cards-grid {
    grid-template-columns: repeat(5, 50%);
    grid-column-gap: 2%;
    overflow: auto;
  }
}
.progress-bar {
    text-align: center;
    background-color: #90bb81;
}
</style>